<template>
  <div class="page_">
    <a-layout class="admin-layout-container">
      <a-layout-header style="background: #fff; padding: 0">
        <div class="logo" />
        <user-avatar></user-avatar>
      </a-layout-header>
      <a-layout-content>
        <div class="content_">
          <div class="inner_">
            <div class="title_">
              {{ $t('login.xgmm') }}
              <label class="desc_">{{
                $t('tips[0]') || '建议您定期更改密码以确保账户安全'
              }}</label>
            </div>
            <a-form
              :form="form"
              @submit="handleChangePwd"
              class="horizontal_form_"
            >
              <a-form-item
                class="item_"
                v-bind="formItemLayout"
                :label="$t('tips[2]') || '原始密码'"
              >
                <a-input
                  v-docorator="['oldPassword']"
                  class="input_"
                  :placeholder="$t('tips[3]') || '请输入原始密码'"
                ></a-input>
              </a-form-item>
              <a-form-item
                class="item_"
                v-bind="formItemLayout"
                :label="$t('tips[4]') || '新密码'"
              >
                <a-input
                  v-decorator="['newPassWord']"
                  class="input_"
                  :placeholder="$t('tips[3]') || '请输入原始密码'"
                ></a-input>
              </a-form-item>
              <a-form-item
                class="item_"
                v-bind="formItemLayout"
                :label="$t('tips[5]') || '确认密码'"
              >
                <a-input
                  v-decorator="['confirmPassword']"
                  class="input_"
                  :placeholder="$t('tips[3]') || '请输入原始密码'"
                ></a-input>
              </a-form-item>
              <a-form-item style="margin-top: 36px;">
                <a-button
                  @click="$router.go(-1)"
                  class="btn_d_"
                  type="default"
                  >{{ $t('tips[6]') || '上一步' }}</a-button
                >
                <a-button
                  html-type="submit"
                  class="btn_c_"
                  style="margin-left: 15px;"
                  type="primary"
                  >{{ $t('tips[7]') || '确认修改密码' }}</a-button
                >
              </a-form-item>
            </a-form>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>
<style lang="scss" scoped>
.page_ {
  background: #f1f2f6;
  height: 100%;
  .ant-layout {
    background: transparent !important;
  }
  .btn_d_ {
    width: 112px;
    height: 36px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 3px;
  }
  .btn_c_ {
    width: 190px;
    height: 36px;
    background: rgba(34, 226, 183, 1);
    border-radius: 3px;
    border: none;
    &:hover {
      background: rgba(34, 226, 183, 0.6);
    }
    &:focus {
      background: rgba(34, 226, 183, 1);
    }
  }
  .content_ {
    width: 875px;
    padding-top: 156px;
    height: calc(100vh - 64px);
    padding-bottom: 100px;
    margin: 0 auto;
    background: #fff;
    .item_ {
      margin-bottom: 14px;
    }
    .input_ {
      height: 36px;
    }
  }
  .inner_ {
    width: 317px;
    margin: 0 auto;
  }
  .title_ {
    font-size: 13px;
    color: #333333;
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 28px;

    .desc_ {
      color: #cccccc;
      font-size: 9px;
    }
  }
}
</style>
<script>
import UserAvatar from '@/components/UserAvatar'
import { exit } from '@/utils/business'
import encrypt from '@/utils/crypt'

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      }
    }
  },
  components: { 'user-avatar': UserAvatar },
  methods: {
    exit,
    changePwd(options) {
      this.$apis
        .changePwd(options)
        .then(res => res.data)
        .then(() => {
          this.$message.success(
            this.$t('tips[39]') || '修改密码成功，请重新登录'
          )
          this.exit()
        })
    },
    handleChangePwd(e) {
      e && e.preventDefault()
      this.form.validateFields((error, values) => {
        if (!error) {
          this.changePwd({
            ...values,
            oldPassword: encrypt(values.oldPassword),
            newPassWord: encrypt(values.newPassWord),
            confirmPassword: encrypt(values.confirmPassword)
          })
        }
      })
    }
  }
}
</script>
